<template>
  <div class="profileOverviewPersonalDetails">
    <div class="profileOverviewPersonalDetails__title">
      {{ $t('profileEdit.title') }}

      <router-link
        v-bind:to="{ name: 'profile.edit' }"
        class="profileOverviewPersonalDetails__titleLink"
      >
        {{ $t('profileOverview.editLinkLabel') }}
      </router-link>
    </div>

    <profile-field
      v-bind:label="$t('profileEdit.nameLabel')"
      v-bind:value="name"
    />

    <profile-field
      v-bind:label="$t('profileEdit.emailLabel')"
      v-bind:value="profile.email"
    />

    <profile-field
      v-if="profile.mobile_number"
      v-bind:label="$t('profileEdit.phonenumberLabel')"
      v-bind:value="profile.mobile_number"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ProfileField from '@/components/profile/ProfileField';

export default {
  components: {
    ProfileField,
  },

  computed: {
    ...mapState('member', ['profile']),

    name() {
      const { first_name, last_name } = this.profile;

      if (!first_name) {
        return this.$t('profileEdit.nameUnknownValue');
      }

      return `${first_name} ${last_name || ''}`;
    },
  },

  created() {
    this.fetchProfile();
  },

  methods: {
    ...mapActions('member', ['fetchProfile']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileOverviewPersonalDetails__title {
  @include paragraph--bold;
  margin: 0 0 rem(24px) 0;
}

.profileOverviewPersonalDetails__titleLink {
  @include label;
  margin: 0 0 0 rem(20px);
  text-decoration: underline;
  color: $color-green;
}
</style>