<template>
  <div class="profileOverviewNotifications">
    <div class="profileOverviewNotifications__title">
      {{ $t('profilePrivacy.title') }}

      <router-link
        v-bind:to="{ name: 'profile.privacy' }"
        class="profileOverviewNotifications__titleLink"
      >
        {{ $t('profileOverview.editLinkLabel') }}
      </router-link>
    </div>

    <p class="profileOverviewNotifications__body">
      {{ body }}
    </p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState('member', ['privacySettings']),

    body() {
      const { notification_weekly, notification_personalised, notification_sms } = this.privacySettings;

      return this.$t('profileOverview.notificationsBody', {
        weekly: this.$t(`profileOverview.notificationsBody${notification_weekly ? 'Applied' : 'Denied'}`),
        personalised: this.$t(`profileOverview.notificationsBody${notification_personalised ? 'Applied' : 'Denied'}`),
        sms: this.$t(`profileOverview.notificationsBody${notification_sms ? 'Applied' : 'Denied'}`),
      });
    }
  },

  created() {
    this.fetchPrivacySettings();
  },

  methods: {
    ...mapActions('member', ['fetchPrivacySettings']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileOverviewNotifications__title {
  @include paragraph--bold;
  margin: 0 0 rem(24px) 0;
}

.profileOverviewNotifications__titleLink {
  @include label;
  margin: 0 0 0 rem(20px);
  text-decoration: underline;
  color: $color-green;
}

.profileOverviewNotifications__body {
  @include label;
}
</style>