<template>
  <div class="profileMemberGetMember">
     <div class="profileMemberGetMember__left">
        <div class="profileMemberGetMember__image">
          <div class="profileMemberGetMember__subTitle">
            {{ $t('profileMemberGetMember.subTitle') }}
          </div>
        </div>
      </div>
      <div class="profileMemberGetMember__right">
        <profile-overview-member-get-member />
      </div>
  </div>
</template>

<script>
import BasePanel from '@/components/BasePanel';
import ProfileOverviewPersonalDetails from '@/components/profile/ProfileOverviewPersonalDetails';
import ProfileOverviewMembership from '@/components/profile/ProfileOverviewMembership';
import ProfileOverviewNotifications from '@/components/profile/ProfileOverviewNotifications';
import ProfileOverviewMemberGetMember from '@/components/profile/ProfileOverviewMemberGetMember';

export default {
  components: {
    BasePanel,
    ProfileOverviewPersonalDetails,
    ProfileOverviewNotifications,
    ProfileOverviewMembership,
    ProfileOverviewMemberGetMember,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileMemberGetMember {
  @include desktop {
    display: flex;
    justify-content: space-between;
  }
  min-height: rem(320px);
}

.profileMemberGetMember__left {
  width: 100%;
  position: relative;

  @include mobile {
    display: none;
  }
}

.profileMemberGetMember__right {
  width: rem(600px);
  padding: 0 rem(12px);

  @include mobile {
    width: auto;
  }
}

.profileMemberGetMember__image {
  position: absolute;
  top: rem(-24px);
  left: rem(-24px);
  border-radius: rem(10px) 0 0 rem(10px);
  width: 100%;
  height: calc(100% + #{rem(48px)});
  background: transparent url('~@/assets/profile/mgm.jpg') no-repeat;
  background-size: cover;
  background-position-x: center;
}

.profileMemberGetMember__subTitle {
  @include heading-1;
  text-align: center;
  margin: rem(22px) auto 0;
  color: white;
}

.profileOverview__panelMembership {
  margin: rem(16px) 0 0 0;
  height: 100%;

  @include desktop {
    margin: 0;
  }
}

</style>