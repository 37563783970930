/**
 * @param {object} response
 * @param {function} callback
 * @returns {boolean}
 */
const getErrors = (response, callback) => {
    let messages = '';

    if (response.status >= 400 && response.data) {
        if (typeof response.data === 'string') {
            messages += `${response.data}\n`;
        } else if (typeof response.data === 'object') {
            let errors = typeof response.data.error !== 'undefined'
                ? response.data.error
                : typeof response.data.errors !== 'undefined'
                    ? response.data.errors
                    : response.data;

            if (Array.isArray(errors)) {
                errors.forEach(err => messages += `${err}\n`);
            } else {
                for (let key in errors) {
                    typeof errors[key] === 'string'
                        ? messages += `${errors[key]}\n`
                        : errors[key].forEach(err => messages += `${err}\n`);
                }
            }
        }
    }

    callback(messages);

    return response.status >= 400;
};

export default getErrors;