<template>
  <div class="profileOverviewMemberGetMember">
    <div>
      <div
        class="profileOverviewMemberGetMember__title"
        v-html-safe="$t('profileMemberGetMember.title')"
      />
      <Form
          v-bind:validation-schema="validationSchema"
          v-slot="{ handleSubmit, isSubmitting }"
      >
        <base-form v-on:submit="handleSubmit($event, onInvite)">
          <base-form-element>
            <base-form-label class="profileOverviewMemberGetMember__info" for-id="email">
              {{ $t('profileMemberGetMember.emailFieldInfo') }}
            </base-form-label>

            <div class="profileOverviewMemberGetMember__inputWrapper">
              <base-form-input
                  type="email"
                  id="email"
                  v-bind:class="{formInput__btn_right: true}"
                  v-bind:disabled="!memberGetMember.can_invite"
                  v-bind:placeholder="`${$t('profileMemberGetMember.emailFieldLabel')}`"
              />

              <base-button
                  modifiers="primary"
                  v-bind:show-spinner="isSubmitting"
                  v-bind:disabled="isSubmitting || !memberGetMember.can_invite"
              >
                {{ $t('profileMemberGetMember.inviteButtonLabel') }}
              </base-button>
            </div>

            <base-form-element-note>
            </base-form-element-note>

            <div class="profileOverviewMemberGetMember__message">
              <base-form-element-error name="email"/>
              <p
                  v-if="errorMessage"
                  class="profileOverviewMemberGetMember__error"
              >
                {{ errorMessage }}
              </p>
              <p
                  v-if="successMessage"
                  class="profileOverviewMemberGetMember__success"
              >
                {{ successMessage }}
              </p>
            </div>
          </base-form-element>
        </base-form>
      </Form>
      <div class="profileOverviewMemberGetMember__badgesWrapper">
        <div class="profileOverviewMemberGetMember__badgesIcons">
          <div
              v-for="n in 6"
              :key="n"
              v-bind:class="{
                'profileOverviewMemberGetMember__badgeIcon': true,
                'profileOverviewMemberGetMember__badgeIcon--active': memberGetMember.invitations >= n,
              }">
          </div>
        </div>
        <div class="profileOverviewMemberGetMember__badgesInfo">
          {{ $t('profileMemberGetMember.footer') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Form} from 'vee-validate';
import * as yup from 'yup';
import BaseForm from '@/components/forms/BaseForm';
import BaseFormElement from '@/components/forms/BaseFormElement';
import BaseFormElementNote from '@/components/forms/BaseFormElementNote';
import BaseFormElementError from '@/components/forms/BaseFormElementError';
import BaseFormLabel from '@/components/forms/BaseFormLabel';
import BaseFormInput from '@/components/forms/BaseFormInput';
import BaseButton from '@/components/BaseButton';
import {mapState, mapActions} from 'vuex';
import {i18n} from '@/plugins/i18n';
import getErrors from "@/utils/getErrors";

export default {
  components: {
    Form,
    BaseForm,
    BaseFormElement,
    BaseFormElementNote,
    BaseFormElementError,
    BaseFormLabel,
    BaseFormInput,
    BaseButton,
  },

  data() {
    return {
      errorMessage: '',
      successMessage: '',
      validationSchema: yup.object({
        email: yup
            .string()
            .email()
            .required(() => i18n.global.t('form.errorRequired')),
      }),
    };
  },

  computed: {
    ...mapState('member', ['memberGetMember']),

    invitations: {
      get() {
        return this.memberGetMember.invitations;
      },
    },
    can_invite: {
      get() {
        return this.memberGetMember.can_invite;
      },
    },
  },

  created() {
    this.fetchMemberGetMember();
  },

  methods: {
    ...mapActions('member', ['fetchMemberGetMember']),

    async onInvite(values, {resetForm}) {
      try {
        this.errorMessage = '';
        this.successMessage = '';

        const response = await this.$store.dispatch('member/memberGetMember', values.email);

        if (getErrors(response, err => this.errorMessage = err)) {
          return;
        }

        this.successMessage = i18n.global.t('profileMemberGetMember.success');
        this.fetchMemberGetMember();

        resetForm();
      } catch (error) {
        console.warn(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileOverviewMemberGetMember {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.profileOverviewMemberGetMember__title {
  @include heading-2;
  margin: 0 0 rem(54px) 0;
  text-align: center;
  white-space: nowrap;
  padding-top: .7rem;

  background: url('~@/assets/profile/mgm_title.png');
  background-repeat: no-repeat;

  background-position: calc(50% - 6rem) calc(0rem);
  background-size: 3rem 3rem;

  @include mobile {
    background-position: calc(50% - 4.6rem) calc(0rem);
    background-size: 2.3rem 2.3rem;
  }
}

.profileOverviewMemberGetMember__success,
.profileOverviewMemberGetMember__error {
  font-size: rem(14px);
  color: $color-error-dark;
}

.profileOverviewMemberGetMember__message {
  min-height: rem(80px);
}

.profileOverviewMemberGetMember__info {
  color: $color-beige--dark;
}

.profileOverviewMemberGetMember__inputWrapper {
  position: relative;

  button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $color-orange;
  }
}

.profileOverviewMemberGetMember__badgesWrapper {
  margin: rem(18px) 0 0;
  text-align: center;

  .profileOverviewMemberGetMember__badgesIcons {
    display: inline-flex;

    .profileOverviewMemberGetMember__badgeIcon {
      background: transparent url('~@/assets/profile/mgm_inactive.png') no-repeat;
      background-size: cover;
      margin: 0 rem(6px) rem(10px);
      width: rem(22px);
      height: rem(26px);

      &--active {
        background: transparent url('~@/assets/profile/mgm_active.png') no-repeat;
        background-size: cover;
      }
    }
  }

  .profileOverviewMemberGetMember__badgesInfo {
    color: $color-beige--dark;
    font-style: italic;
    font-size: rem(13px);
  }
}
</style>